export default {
  props: {
    flag: {
      type: Object,
      required: true,
    },

    methods: {
      type: Array,
      required: true,
    },
  },
}
