import { render, staticRenderFns } from "./PaymentMethodsItem.vue?vue&type=template&id=a8dd09ea&scoped=true"
import script from "./PaymentMethodsItem.js?vue&type=script&lang=js&external"
export * from "./PaymentMethodsItem.js?vue&type=script&lang=js&external"
import style0 from "./PaymentMethodsItem.scss?vue&type=style&index=0&id=a8dd09ea&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8dd09ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default})
